.main-content{
  min-height: 92vh;
  padding-top: 9vh;
}

.navbar {
  min-height: 9vh;
}

.footer {
  min-height: 8vh;
  max-height: 8vh;
  height: 8vh;
}

html { scroll-padding-top: 10vh; }

/*animations*/
.zoom {
  transition: transform .4s;
  /* Animation */
  width: auto;
  height: auto;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bllagelogo{
  transition: 0.3s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.bllagelogo:hover {
  transition: 0.3s;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}